<template>
  <v-card light>
    <!-- LOCKED -->
    <v-container v-if="tournament.locked">
      <v-row dense>
        <v-col class="text-center" >
          <v-card color="grey lighten-4">
            <v-card-title>
              <h3>{{tournament.name}} has been locked for editing</h3>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- PUBLIC -->
    <v-container v-else-if="!userIsAdmin">
      <v-row dense>
        <v-col cols="12">
          <v-card color="grey lighten-4">
            <v-card-text class="title">
              This team is currently {{currentDivision.acceptanceOnly ? 'pending' : 'on the waitlist'}}.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- ADMIN -->
    <v-container v-else>
      <v-row dense>
        <v-col cols="12">
          <v-card color="grey lighten-4">
            <v-card-text>
              <h3>This team is currently {{currentDivision.acceptanceOnly ? 'pending' : 'on the waitlist'}}.</h3>
              <v-btn
                v-if="waitlistRoute"
                color="color3"
                class="px-0 mx-0"
                text
                :to="waitlistRoute"
                >Manage</v-btn>
            </v-card-text>
            <v-expand-transition>
              <div v-if="showAdd">
                <v-card-text>
                  <div class="title">What would you like to do?</div>

                  <v-checkbox :label="`Let the team in ${currentDivision.name}`" v-model="add"></v-checkbox>
                  <v-checkbox label="Send them a note letting them know they are in" v-model="note"></v-checkbox>
                </v-card-text>

              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['tournament', 'team', 'currentDivision'],
  data () {
    return {
      showAdd: false,
      add: true,
      note: false
    }
  },
  computed: {
    ...mapGetters(['user', 'publicView']),
    userIsAdmin () {
      return this.user && !this.publicView && this.user.isTournamentAdmin(this.tournament)
    },
    waitlistRoute () {
      return this.tournament && this.currentDivision && { name: 'division-waitlist', params: { tournamentId: this.tournament.id, divisionId: this.currentDivision.id } }
    }
  }
}
</script>

<style>

</style>
